<template>
  <div class="info-cards">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.info-cards {
  &:deep(.info-card) {
    border-radius: 0;
    margin-top: -1px;
    &:first-child {
      margin-top: 0;
      border-top-left-radius: $border-radius-lg;
      border-top-right-radius: $border-radius-lg;
    }
    &:last-child {
      border-bottom-left-radius: $border-radius-lg;
      border-bottom-right-radius: $border-radius-lg;
    }
  }
}
</style>
