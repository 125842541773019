
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { ButtonSize, SizeProp, ButtonColor } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmButton,
  },
  props: {
    visibleItemsCount: {
      type: Number,
    },
    btnColor: {
      type: String as PropType<ButtonColor>,
    },
    size: {
      type: String as SizeProp<ButtonSize>,
      default: '',
    },
    horizontalIcon: {
      type: Boolean,
    },
  },
})
