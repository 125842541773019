
import { defineComponent, ref } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import FileAttach from '@/components/shared/utils/FileAttach.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

export default defineComponent({
  components: {
    TmDropdownItem,
    TmAvatar,
    TmButton,
    TmDropdown,
    TmModal,
    FileAttach,
  },
  props: {
    url: {
      type: String,
    },
    itemName: {
      type: String,
      default: 'photo',
    },
    icon: {
      type: String,
      default: 'person',
    },
    name: {
      type: String,
    },
    avatarColor: {
      type: String,
      default: 'gray',
    },
    isCropAvatarVisible: {
      type: Boolean,
      default: true,
    },
    avatarSize: {
      type: String,
      default: '100px',
    },
    avatarIconSize: {
      type: String,
      default: '50px',
    },
  },
  emits: ['updateAvatar', 'deleteAvatar'],
  setup(props, context) {
    const cropAvatarModal = ref()
    const showCropAvatarModal = () => {
      context.emit('updateAvatar')
      if (props.isCropAvatarVisible) cropAvatarModal.value.openModal()
    }
    const saveCropAvatar = (hideModal: any) => {
      hideModal()
      context.emit('updateAvatar')
    }
    const hasStatusSlot = context.slots.status

    return {
      cropAvatarModal,
      showCropAvatarModal,
      saveCropAvatar,
      hasStatusSlot,
    }
  },
})
