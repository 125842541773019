
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import PageScrollContainer from '@/components/layout/PageScrollContainer.vue'
import PageContentSidebar from '@/components/layout/pageContent/sidebar/PageContentSidebar.vue'
import type { SizeProp, BreadcrumbsLink } from '@/definitions/shared/types'
import { useBreakpoints } from '@/compositions/breakpoints'
import TmBreadcrumbs from '@/components/shared/responsive/breadcrumbs/TmBreadcrumbs.vue'
import kebabCase from 'lodash/kebabCase'

export default defineComponent({
  components: {
    PageScrollContainer,
    PageContentSidebar,
    TmBreadcrumbs,
  },
  props: {
    sidebarExpandVisibleOnHover: {
      type: Boolean,
    },
    noScroll: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    titleClass: {
      type: String,
    },
    initialSidebarWidth: {
      type: Number,
    },
    hideTitle: {
      type: Boolean,
    },
    menuTitle: {
      type: String,
    },
    size: {
      type: String as SizeProp<'' | 'xSmall' | 'small' | 'medium' | 'xMedium' | 'large'>,
      default: '',
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    bgShade: {
      type: Boolean,
      default: false,
    },
    breadcrumbs: {
      type: Array as PropType<BreadcrumbsLink[]>,
    },
    overflowHidden: {
      type: Boolean,
    },
    overflowVisible: {
      type: Boolean,
    },
    collapseSidebar: {
      type: Boolean,
    },
    sidebarOverlay: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const hasSidebarBottomSlot = !!context.slots['sidebar-bottom']
    const hasSidebarSlot = !!context.slots['filter-bar-slot']
    const hasContentTopLeftSlot = !!context.slots['page-content-top-left']
    const hasContentWhiteSlot = !!context.slots['page-content-white']
    const hasContentBottomSlot = !!context.slots['page-content-bottom']
    const { isMdMax } = useBreakpoints()
    const sidebarHidden = ref(props.collapseSidebar)
    const sidebarShowTransition = ref(true)

    return {
      hasSidebarSlot,
      sidebarShowTransition,
      isMdMax,
      sidebarHidden,
      hasSidebarBottomSlot,
      hasContentTopLeftSlot,
      hasContentWhiteSlot,
      hasContentBottomSlot,
      kebabCase,
    }
  },
})
