import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25a19402"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "page-content__breadcrumbs"
}
const _hoisted_2 = { class: "ml-auto" }
const _hoisted_3 = {
  key: 1,
  class: "flex-grow-1 d-flex align-end flex-wrap"
}
const _hoisted_4 = { class: "emphasize--text" }
const _hoisted_5 = { class: "flex-shrink-0" }
const _hoisted_6 = {
  key: 0,
  class: "page-content__content-white"
}
const _hoisted_7 = {
  key: 0,
  class: "page-title-20 d-flex align-center mb-6 emphasize--text"
}
const _hoisted_8 = {
  key: 0,
  class: "page-content__bottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_content_sidebar = _resolveComponent("page-content-sidebar")!
  const _component_tm_breadcrumbs = _resolveComponent("tm-breadcrumbs")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["page-content", {
      'page-content--bg-shade': _ctx.bgShade,
      'page-content--sidebar-hidden': _ctx.sidebarHidden,
    }])
  }, [
    (_ctx.hasSidebarSlot && !_ctx.isMdMax)
      ? (_openBlock(), _createBlock(_component_page_content_sidebar, {
          key: 0,
          "sidebar-collapsed": _ctx.sidebarHidden,
          "onUpdate:sidebar-collapsed": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sidebarHidden) = $event)),
          "sidebar-show-transition": _ctx.sidebarShowTransition,
          "onUpdate:sidebar-show-transition": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sidebarShowTransition) = $event)),
          "expand-visible-on-hover": _ctx.sidebarExpandVisibleOnHover,
          "menu-title": _ctx.menuTitle,
          "initial-width": _ctx.initialSidebarWidth
        }, _createSlots({
          "filter-bar-dropdown": _withCtx(() => [
            _renderSlot(_ctx.$slots, "filter-bar-dropdown", {}, undefined, true)
          ]),
          "filter-bar-slot": _withCtx(() => [
            _renderSlot(_ctx.$slots, "filter-bar-slot", {}, undefined, true)
          ]),
          _: 2
        }, [
          (_ctx.hasSidebarBottomSlot)
            ? {
                name: "sidebar-bottom",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "sidebar-bottom", {}, undefined, true)
                ])
              }
            : undefined
        ]), 1032, ["sidebar-collapsed", "sidebar-show-transition", "expand-visible-on-hover", "menu-title", "initial-width"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["page-content__main-wrap", {
        'page-content__main-wrap--sidebar': _ctx.hasSidebarSlot,
        'page-content__main-wrap--transition': _ctx.sidebarShowTransition,
        'page-content__main-wrap--sidebar-overlay': _ctx.sidebarOverlay,
      }])
    }, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.noScroll ? 'div' : 'page-scroll-container'), {
        class: _normalizeClass(["h100pr flex-grow-1", { 'overflow-hidden': _ctx.noScroll }])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["page-content__main", {
            'pa-0': _ctx.noPadding,
            [`page-content__main--${_ctx.kebabCase(_ctx.size)}`]: _ctx.size,
            'page-content__main--with-breadcrumbs': _ctx.breadcrumbs,
          }])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["page-content__main-block", {
              'page-content__main-block--overflow-visible': _ctx.overflowVisible,
            }])
            }, [
              _createElementVNode("div", null, [
                _renderSlot(_ctx.$slots, "before", {}, undefined, true)
              ]),
              (_ctx.breadcrumbs)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_tm_breadcrumbs, { links: _ctx.breadcrumbs }, null, 8, ["links"]),
                    _createElementVNode("div", _hoisted_2, [
                      _renderSlot(_ctx.$slots, "breadcrumbs-after", {}, undefined, true)
                    ])
                  ]))
                : (_ctx.title || _ctx.hasContentTopLeftSlot || _ctx.$slots.title)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(['page-content__top', _ctx.titleClass])
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["page-content__top-block", {
                  'hasContentTopLeftSlot': _ctx.hasContentTopLeftSlot,
                }])
                      }, [
                        (_ctx.hasContentTopLeftSlot)
                          ? _renderSlot(_ctx.$slots, "page-content-top-left", { key: 0 }, undefined, true)
                          : ((_ctx.title || _ctx.$slots.title) && !_ctx.hideTitle)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                _renderSlot(_ctx.$slots, "title", {}, () => [
                                  _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.title), 1)
                                ], true),
                                _renderSlot(_ctx.$slots, "next-to-title", {}, undefined, true)
                              ]))
                            : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_5, [
                          _renderSlot(_ctx.$slots, "page-content-top-right", {}, undefined, true)
                        ])
                      ], 2),
                      _renderSlot(_ctx.$slots, "page-content-top-after", {}, undefined, true)
                    ], 2))
                  : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["page-content__content", {
                'page-content__content--overflow-hidden': _ctx.overflowHidden || _ctx.noScroll,
              }])
              }, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true),
                (_ctx.hasContentWhiteSlot)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _renderSlot(_ctx.$slots, "title", {}, () => [
                        (_ctx.breadcrumbs && _ctx.title && !_ctx.hideTitle)
                          ? (_openBlock(), _createElementBlock("h1", _hoisted_7, _toDisplayString(_ctx.title), 1))
                          : _createCommentVNode("", true)
                      ], true),
                      _renderSlot(_ctx.$slots, "page-content-white", {}, undefined, true)
                    ]))
                  : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "after-content-white", {}, undefined, true)
              ], 2)
            ], 2)
          ], 2),
          (_ctx.hasContentBottomSlot)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _renderSlot(_ctx.$slots, "page-content-bottom", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["class"]))
    ], 2)
  ], 2))
}