import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44674acf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center w100pr" }
const _hoisted_2 = { class: "details-hero__avatar-container" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "body-text-regular-14 emphasize--text lh-20 align-center w100pr" }
const _hoisted_5 = {
  key: 0,
  class: "details-hero__list"
}
const _hoisted_6 = { class: "details-hero__list-items" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "details-hero__bottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_upload_avatar = _resolveComponent("tm-upload-avatar")!
  const _component_tm_editable_text = _resolveComponent("tm-editable-text")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_auto_size_panel_mobile_activator = _resolveComponent("tm-auto-size-panel-mobile-activator")!
  const _component_tm_auto_size_panel = _resolveComponent("tm-auto-size-panel")!
  const _component_tm_tabs_nav = _resolveComponent("tm-tabs-nav")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["details-hero", {
      'details-hero--has-bottom': _ctx.$slots['bottom-left'] || _ctx.tabs.length,
      'details-hero--square-avatar': _ctx.squareAvatar,
      'details-hero--ellipsis': _ctx.ellipsis,
      [`pattern-${_ctx.pattern}`]: true,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "avatar", {}, () => [
          (!_ctx.avatarEditable)
            ? (_openBlock(), _createBlock(_component_tm_avatar, {
                key: 0,
                "custom-size": _ctx.avatarSize,
                "custom-icon": _ctx.icon,
                "custom-icon-class": _ctx.iconClass,
                name: _ctx.title,
                emoji: _ctx.emoji,
                url: _ctx.avatarValue,
                "placeholder-type": _ctx.avatarPlaceholderType,
                "placeholder-custom-size": _ctx.avatarPlaceholderSize,
                color: _ctx.avatarColor,
                outlined: !_ctx.hideAvatarOutline,
                class: _normalizeClass({
              'details-hero__avatar': !_ctx.avatarColor,
            })
              }, null, 8, ["custom-size", "custom-icon", "custom-icon-class", "name", "emoji", "url", "placeholder-type", "placeholder-custom-size", "color", "outlined", "class"]))
            : (_openBlock(), _createBlock(_component_tm_upload_avatar, {
                key: 1,
                class: "details-hero__avatar",
                name: _ctx.title,
                url: _ctx.avatarValue,
                "avatar-icon-size": _ctx.avatarPlaceholderSize,
                "avatar-size": _ctx.avatarSize,
                onUpdateAvatar: _ctx.uploadAvatar,
                onDeleteAvatar: _ctx.deleteAvatar
              }, null, 8, ["name", "url", "avatar-icon-size", "avatar-size", "onUpdateAvatar", "onDeleteAvatar"]))
        ], true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["details-hero__title", {
          'details-hero__title--with-subtitle': _ctx.hasDefaultSlot || _ctx.infos
        }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["details-hero__title-text", {
            [`details-hero__title-text--${_ctx.kebabCase(_ctx.titleSize)}`]: _ctx.titleSize,
          }])
        }, [
          _renderSlot(_ctx.$slots, "title", {}, () => [
            (_ctx.titleEditable)
              ? (_openBlock(), _createBlock(_component_tm_editable_text, {
                  key: 0,
                  "model-value": _ctx.title,
                  "max-length": 80,
                  class: "details-hero__title-field",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('updateTitle', $event)))
                }, null, 8, ["model-value"]))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "details-hero__title-text-in",
                  textContent: _toDisplayString(_ctx.title)
                }, null, 8, _hoisted_3))
          ], true)
        ], 2),
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            (_ctx.infos)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("ul", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infos, (info) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: info.label,
                        class: _normalizeClass(["details-hero__list-item", {
                    'details-hero__list-item--show-full': info.showFull
                  }])
                      }, [
                        (info.url)
                          ? (_openBlock(), _createBlock(_component_router_link, {
                              key: 0,
                              to: info.url,
                              class: "d-flex align-center font-color-inherit font-weight-normal blue-on-hover"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(info.label), 1),
                                _createVNode(_component_tm_icon, {
                                  size: "xSmall",
                                  name: "north_east",
                                  class: "distinct--text ml-1"
                                })
                              ]),
                              _: 2
                            }, 1032, ["to"]))
                          : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(info.label), 1))
                      ], 2))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ], true),
          (_ctx.$slots['after-info'])
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _renderSlot(_ctx.$slots, "after-info", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2),
      (!!_ctx.$slots.right)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            style: _normalizeStyle({
          'height': _ctx.avatarSize
        }),
            class: "details-hero__buttons"
          }, [
            _createVNode(_component_tm_auto_size_panel, {
              "max-visible-items": _ctx.isSmMax ? 1 : _ctx.maxVisibleButtons,
              class: "justify-end",
              "extra-offset-for-focus": ""
            }, {
              "dropdown-activator": _withCtx(({ visibleItemsCount }) => [
                (_ctx.isSmMax)
                  ? (_openBlock(), _createBlock(_component_tm_button, {
                      key: 0,
                      size: "large",
                      "icon-only": ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_tm_icon, { name: "more_vert" })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_tm_auto_size_panel_mobile_activator, {
                      key: 1,
                      size: "large",
                      "visible-items-count": visibleItemsCount
                    }, null, 8, ["visible-items-count"]))
              ]),
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "right", {}, undefined, true)
              ]),
              _: 3
            }, 8, ["max-visible-items"])
          ], 4))
        : _createCommentVNode("", true)
    ]),
    (_ctx.$slots['bottom-left'] || _ctx.tabs.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _renderSlot(_ctx.$slots, "bottom-left", {}, () => [
            _createVNode(_component_tm_tabs_nav, {
              modelValue: _ctx.currentRoute,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentRoute) = $event)),
              tabs: _ctx.tabs,
              "no-border": "",
              "use-router": _ctx.useRouterTabs,
              "with-dropdown": ""
            }, null, 8, ["modelValue", "tabs", "use-router"])
          ], true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}