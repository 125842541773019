
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import { useModes } from '@/compositions/modes'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { editorBtns } from '@/definitions/shared/editor/data'

export default defineComponent({
  components: {
    TmButton,
    TmFormLine,
    InfoCard,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const heading = ref(isEmptyMode.value ? '' : 'Thank you!')
    const message = ref(isEmptyMode.value ? '' : 'The review is very important to us. We believe we can do even better in the future based on your opinion.')
    const additionalComments = ref(true)

    const btnsList: EditorButtonType[] = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.image,
    ]

    return {
      heading,
      message,
      additionalComments,
      btnsList,
    }
  },
})
