
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'SatisfactionStatus',
  props: {
    text: {
      type: String,
    },
    satisfied: {
      type: Boolean,
    },
    editable: {
      type: Boolean,
    },
  },
  emits: ['updateText'],
  setup(props, { emit }) {
    const statusSatisfiedValue = ref(props.text)
    const isEdit = ref(false)
    const handleBlur = () => {
      isEdit.value = false
      emit('updateText', statusSatisfiedValue.value)
    }

    return {
      handleBlur,
      statusSatisfiedValue,
      isEdit,
    }
  },
})
