
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmToggleButtons from '@/components/shared/TmToggleButtons.vue'
import { openFileUpload } from '@/services/file'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmToggleButtons,
    TmButton,
    TmFormLine,
  },
  setup() {
    const { openModal } = useModals()
    const logoType = ref('workspace')
    const deleteLogo = () => {
      openModal('confirmation', {
        title: 'Delete logo',
        text: 'You are going to delete logo. Are you sure you want to do this?',
        btnColor: 'error',
        btnText: 'Delete',
      })
    }

    return {
      deleteLogo,
      logoType,
      openFileUpload,
    }
  },
})
