
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import SatisfactionStatuses from '@/components/pages/tickets/settings/feedbackSurvey/SatisfactionStatuses.vue'
import { useModals } from '@/compositions/modals'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { editorBtns } from '@/definitions/shared/editor/data'
import LogoSelect from '@/components/pages/shared/LogoSelect.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    LogoSelect,
    TmButton,
    TmFormLine,
    TmDropdown,
    TmDropdownItem,
    InfoCard,
    SatisfactionStatuses,
  },
  setup() {
    const { openModal } = useModals()
    const { isEmptyMode } = useModes()

    const subject = ref(isEmptyMode.value ? '' : 'Feedback: {{ticket_id}} - {{ticket_subject}}')
    const heading = ref(isEmptyMode.value ? '' : 'Your feedback is important')
    const question = ref(isEmptyMode.value ? '' : 'How was our support?')

    const message = ref(isEmptyMode.value ? '' : 'Hi {{first.name}}, thanks for getting in touch with us!\n\nYour ticket ({{ticket.id}} - {{ticket.subject}}) was marked solved 3 days ago. We would like to get your feedback.')
    const messageAfter = ref(isEmptyMode.value ? '' : 'The ticket transcript is attached to this email, in case you would like to review it again.\n\nThank you,\nTextmagic team')
    const footer = ref(isEmptyMode.value ? '' : 'This email was sent to you by Textmagic Ltd.\n34 Thame Road, Great Haseley, OX44 7JF, United Kingdom')

    const btnsList: EditorButtonType[] = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.tags,
      editorBtns.image,
    ]
    const tags = [
      'First name',
      'Last name',
      'Phone',
      'Email',
      'Company name',
      'Test field',
    ]

    const satisfactions = ref([
      { text: 'Good, I’m satisfied', satisfied: true },
      { text: 'Bad, I’m dissatisfied', satisfied: false },
    ])

    const includeTicketHistory = ref(true)

    const feedbackTextsModal = ref({
      footerMessage: 'This email was sent to you by Textmagic.\n34 Thame Road, Great Haseley, OX44 7JF, United Kingdom',
      emailSubject: 'Ticket updated: 394588 - I need help to reset my password',
      name: 'John',
      surveyHeading: 'Your feedback is important',
      satisfactions: satisfactions.value,
      afterMessage: 'The ticket transcript is attached to this email, in case you would like to review it again.<br/><br/>Thank you<br/>Textmagic team',
      introMessage: 'Thanks for getting in touch with us!<br/><br/>Your ticket <span class="font-weight-semi-bold">(394588 - I need help to reset my password)</span> was marked solved 3 days ago. We would like to get your feedback.',
    })

    const openEmailPreview = () => {
      openModal('emailPreview', {
        modalTitle: 'Feedback survey email preview',
        emailType: 'Ticket updated',
        feedbackTextsModal: feedbackTextsModal.value,
      })
    }

    return {
      openModal,
      openEmailPreview,
      footer,
      includeTicketHistory,
      subject,
      message,
      btnsList,
      tags,
      messageAfter,
      satisfactions,
      heading,
      question,
    }
  },
})
