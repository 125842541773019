
import { defineComponent } from 'vue'
import _ from 'lodash'
import type { SizeProp } from '@/definitions/shared/types'

export default defineComponent({
  props: {
    modelValue: {
      type: [String, Object, Boolean],
    },
    size: {
      type: String as SizeProp<'small' | 'medium' | 'large'>,
      default: 'medium',
    },
    fullwidth: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const btnClick = (val: string | Record<string, any> | boolean) => {
      context.emit('update:modelValue', val)
    }

    return {
      btnClick,
      kebabCase: _.kebabCase,
    }
  },
})
