
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import SatisfactionStatus from '@/components/pages/tickets/settings/feedbackSurvey/SatisfactionStatus.vue'
import type { SatisfactionType } from '@/definitions/tickets/types'

export default defineComponent({
  name: 'SatisfactionStatuses',
  components: { SatisfactionStatus },
  props: {
    satisfactions: {
      type: Object as PropType<SatisfactionType>,
    },
    editable: {
      type: Boolean,
    },
  },
  setup(props) {
    const satisfactionsValue = ref(props.satisfactions)

    return {
      satisfactionsValue,
    }
  },
})
