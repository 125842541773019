import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73f89b9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "satisfaction-statuses" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_satisfaction_status = _resolveComponent("satisfaction-status")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.satisfactionsValue, (satisfaction) => {
      return (_openBlock(), _createBlock(_component_satisfaction_status, {
        key: satisfaction.text,
        text: satisfaction.text,
        satisfied: satisfaction.satisfied,
        editable: _ctx.editable,
        onUpdateText: ($event: any) => (satisfaction.text = $event)
      }, null, 8, ["text", "satisfied", "editable", "onUpdateText"]))
    }), 128))
  ]))
}