
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import { pageContentClassName } from '@/definitions/_general/_data/general'

export default defineComponent({
  components: {
    TmTooltip,
    TmScrollbar,
  },
  props: {
    expandVisibleOnHover: {
      type: Boolean,
    },
    menuTitle: {
      type: String,
    },
    sidebarCollapsed: {
      type: Boolean,
    },
    sidebarShowTransition: {
      type: Boolean,
    },
    initialWidth: {
      type: Number,
      default: 245,
    },
  },
  emits: ['update:sidebar-collapsed', 'update:sidebar-show-transition'],
  setup(props, context) {
    const resizerRef = ref()
    const sidebarWidth = ref(props.initialWidth)
    const hasSidebarBottomSlot = !!context.slots['sidebar-bottom']
    const sidebarInHover = ref(false)
    let pageContentElement: HTMLElement
    let resizerElement: HTMLElement
    let resizeObserver: ResizeObserver

    const btnClickHandle = () => {
      context.emit('update:sidebar-collapsed', !props.sidebarCollapsed)
      sidebarInHover.value = false
    }

    const styleUpdate = (elementWidth: number) => {
      const minWidth = 200
      const maxWidth = pageContentElement.offsetWidth * 0.3 // 30% of page
      sidebarWidth.value = elementWidth <= minWidth ? minWidth : elementWidth >= maxWidth ? maxWidth : elementWidth

      pageContentElement.style.setProperty('--page-content-sidebar-width', sidebarWidth.value + 'px')
    }

    const stopResize = () => {
      context.emit('update:sidebar-show-transition', true)
      window.removeEventListener('mousemove', resize)
    }

    const resize = (e: MouseEvent) => {
      const offset = e.pageX - pageContentElement.getBoundingClientRect().left
      context.emit('update:sidebar-show-transition', false)

      styleUpdate(offset)
    }

    const onResizerMouseDown = (e: MouseEvent) => {
      e.preventDefault()
      window.addEventListener('mousemove', resize)
      window.addEventListener('mouseup', stopResize)
    }

    onMounted(() => {
      pageContentElement = document.querySelector(`.${pageContentClassName}`)!
      resizerElement = resizerRef.value

      resizeObserver = new ResizeObserver(() => {
        styleUpdate(sidebarWidth.value)
      })

      if (pageContentElement) {
        resizeObserver.observe(pageContentElement)
      }

      resizerElement.addEventListener('mousedown', onResizerMouseDown)
    })

    onUnmounted(() => {
      resizerElement.removeEventListener('mousedown', onResizerMouseDown)
      resizeObserver.disconnect()
    })

    return {
      btnClickHandle,
      hasSidebarBottomSlot,
      resizerRef,
      sidebarInHover,
    }
  },
})
