
import type { PropType } from 'vue'
import { defineComponent, ref, watch } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmUploadAvatar from '@/components/shared/TmUploadAvatar.vue'
import TmEditableText from '@/components/shared/TmEditableText.vue'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelMobileActivator from '@/components/shared/autoSize/panel/TmAutoSizePanelMobileActivator.vue'
import TmTabsNav from '@/components/shared/TmTabsNav.vue'
import type { AvatarColorVariant, Tab, DetailsHeroPattern, EmojiNameType } from '@/definitions/shared/types'
import { useBreakpoints } from '@/compositions/breakpoints'
import kebabCase from 'lodash/kebabCase'

type Info = {
  label: string;
  url?: string;
}

export default defineComponent({
  name: 'DetailsHero',
  components: {
    TmEditableText,
    TmAvatar,
    TmButton,
    TmAutoSizePanel,
    TmAutoSizePanelMobileActivator,
    TmTabsNav,
    TmUploadAvatar,
  },
  props: {
    pattern: {
      type: String as PropType<DetailsHeroPattern>,
      default: 'blue',
    },
    currentTab: {
      type: String,
      default: '',
    },
    tabs: {
      type: Array as PropType<Tab[]>,
      default: () => [],
    },
    icon: {
      type: String,
    },
    iconClass: {
      type: String,
    },
    infos: {
      type: Array as PropType<Info[]>,
    },
    avatarUrl: {
      type: String,
    },
    avatarEditable: {
      type: Boolean,
    },
    avatarSize: {
      type: String,
      default: '50px',
    },
    avatarPlaceholderSize: {
      type: String,
      default: '24px',
    },
    avatarPlaceholderType: {
      type: String as PropType<'icon' | 'symbol'>,
      default: 'icon',
    },
    avatarReplace: {
      type: String,
      default: 'https://randomuser.me/api/portraits/men/11.jpg',
    },
    hideAvatarOutline: {
      type: Boolean,
    },
    squareAvatar: {
      type: Boolean,
    },
    titleSize: {
      type: String as PropType<'' | 'large'>,
    },
    title: {
      type: String,
    },
    titleEditable: {
      type: Boolean,
      default: false,
    },
    avatarColor: {
      type: String as PropType<AvatarColorVariant>,
    },
    maxVisibleButtons: {
      type: Number,
      default: 4,
    },
    emoji: {
      type: String as PropType<EmojiNameType>,
    },
    ellipsis: {
      type: Boolean,
    },
    useRouterTabs: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['updateTitle', 'update:currentTab'],
  setup(props, context) {
    const { isMdMax, isSmMax } = useBreakpoints()
    const hasDefaultSlot = !!context.slots.default
    const currentRoute = ref(props.currentTab)
    const avatarValue = ref(props.avatarUrl)
    const uploadAvatar = () => {
      avatarValue.value = props.avatarReplace
    }
    const deleteAvatar = () => {
      avatarValue.value = ''
    }

    watch(() => currentRoute.value, (val) => {
      context.emit('update:currentTab', val)
    })

    return {
      isMdMax,
      hasDefaultSlot,
      avatarValue,
      currentRoute,
      kebabCase,
      isSmMax,
      uploadAvatar,
      deleteAvatar,
    }
  },
})
