import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn_group = _resolveComponent("q-btn-group")!

  return (_openBlock(), _createBlock(_component_q_btn_group, {
    class: _normalizeClass(["tm-toggle-btns", {
      [`tm-toggle-btns--${_ctx.kebabCase(_ctx.size)}`]: _ctx.size,
      'tm-toggle-btns--fullwidth': _ctx.fullwidth,
      'tm-toggle-btns--dark': _ctx.dark,
    }]),
    unelevated: ""
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({ btnClick: _ctx.btnClick })), undefined, true)
    ]),
    _: 3
  }, 8, ["class"]))
}