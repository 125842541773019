import { computed, ref } from 'vue'
import type { ComputedRef } from 'vue'

type provideDataFunc = (count: number, discardFunc?: () => void) => void
type discardFunc = () => void | null
type UseBottomBarVal = {
  provideData: provideDataFunc,
  changesCounter: ComputedRef<number>,
  getDiscardFunc: ComputedRef<discardFunc>,
}

const changes = ref(0)
const defaultDiscardFunc = () => {
  changes.value = 0
}
const userDiscardFunc = ref(() => {})
const discard = () => {
  defaultDiscardFunc()
  userDiscardFunc.value()
}

export const useBottomBar = (): UseBottomBarVal => {
  const provideData: provideDataFunc = (count, discardFunc) => {
    changes.value = count
    if (discardFunc) {
      userDiscardFunc.value = discardFunc
    }
  }
  const changesCounter = computed(() => changes.value)
  const getDiscardFunc = computed(() => discard)

  return {
    provideData,
    changesCounter,
    getDiscardFunc,
  }
}
