import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc08f4fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["contenteditable"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "inputEl",
    class: _normalizeClass(["tm-editable-text", {
      'tm-editable-text--overflow': _ctx.overflow,
    }]),
    contenteditable: _ctx.isFocused,
    tabindex: "0",
    style: _normalizeStyle(`min-height: ${_ctx.minHeight}`),
    onKeydown: [
      _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
      _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleEnter && _ctx.handleEnter(...args)), ["enter"]))
    ],
    onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
    onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isFocused = true))
  }, [
    _createTextVNode(_toDisplayString(_ctx.value) + " ", 1),
    (_ctx.withEditIcon && !_ctx.isFocused)
      ? (_openBlock(), _createBlock(_component_tm_icon, {
          key: 0,
          name: "edit",
          class: "ml-1 light--text"
        }))
      : _createCommentVNode("", true)
  ], 46, _hoisted_1))
}