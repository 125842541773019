import type { TmFileExtension } from '@/definitions/shared/types'

type FileFormat = {
  extension: TmFileExtension;
  title: string;
}
export const getFile = (fileName: string): FileFormat => {
  const file = fileName.split('.')
  const extension = file.pop()
  const title = file.join('')

  return {
    extension: extension || '',
    title: title,
  }
}

export const getFileSize = (size: number): string => {
  return size.toString().length < 7 ? `${Math.round(size / 1024)} KB` : `${Math.round(size / 1024 / 1000)} MB`
}

export const openFileUpload = (): Promise<File> => {
  let isFileUploaded = false

  return new Promise((resolve) => {
    const input = document.createElement('input')
    input.style.display = 'none'
    input.setAttribute('type', 'file')
    document.body.appendChild(input)

    input.addEventListener('change', () => {
      isFileUploaded = true

      if (input.files) {
        resolve(input.files[0])
      }

      input.remove()
    })

    window.addEventListener('focus', () => {
      setTimeout(() => {
        if (!isFileUploaded) {
          input.remove()
        }
      }, 100)
    }, { once: true })

    input.click()
  })
}
