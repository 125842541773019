
import { defineComponent, ref, watch } from 'vue'
import { keyCodes } from '@/definitions/_general/_data/keyCodes'

export default defineComponent({
  name: 'TmEditableText',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    minHeight: {
      type: String,
      default: '32px',
    },
    maxLength: {
      type: Number,
      default: 0,
    },
    withEditIcon: {
      type: Boolean,
    },
    overflow: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'handleEnter'],
  setup(props, context) {
    const inputEl = ref()
    const isFocused = ref(false)
    const value = ref(props.modelValue)

    watch(
      () => props.modelValue,
      (newValue) => {
        if (props.maxLength && newValue.length > props.maxLength) {
          return
        }
        value.value = newValue
      }
    )

    const moveCaretToEnd = () => {
      const el: any = inputEl.value
      const range: any = document.createRange()
      const sel: any = window.getSelection()
      const countElement: number = el.childNodes.length - 1
      range.setStart(
        el.childNodes[countElement],
        el.childNodes[countElement].length || 1
      )
      range.collapse(true)
      sel.removeAllRanges()
      sel.addRange(range)
    }

    const handleInput = (e: any) => {
      if (props.maxLength && inputEl.value.outerText.length >= props.maxLength && e.keyCode !== keyCodes.keyBackspace) {
        e.preventDefault()
        return
      }
      moveCaretToEnd()
      value.value = inputEl.value.outerText
    }
    const handleBlur = (e: any) => {
      e.preventDefault()
      inputEl.value.scrollLeft = 0
      inputEl.value.blur()
      isFocused.value = false
      context.emit('update:modelValue', value.value)
    }
    const handleEnter = (e: any) => {
      context.emit('handleEnter')
      handleBlur(e)
    }

    return {
      inputEl,
      value,
      isFocused,
      handleEnter,
      handleInput,
      handleBlur,
    }
  },
})
